.table-header {
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-top: 40px;
  height: 200px;
}
.reset-form{
  cursor: pointer;
  color: #4f8180;
  font-weight: 500;
}

.custom-store{
  font-weight: bolder;
}

.no-clicks {
  pointer-events: none;
  cursor: none;
}

.action-css {
  display: flex;
  justify-content: space-between;
}

.div-container {
  margin-top: 10px;
  margin-left: 50px;
}

.div-container-bottom{
  justify-content: flex-end;
  display: flex;
}

.div-container-bottom-right{
  justify-content: flex-end;
  display: flex;
  margin-right: 20px;
}

.custom-width{
  width: 24vh;
}

.cursor {
  cursor: pointer;
}

.approved {
  background-color: #7DEBA3;
}

.rejected {
  background-color: #D6D6D6;
}

.boxStyle {
  margin-left: 30px;
}

.whiteBox {
  display:inline-block;
  background: #fff;
  border-radius: 4px;
  padding: 6px;
  padding-left: 15px; 
  margin-right: 5px;
  box-shadow: 0px 1px 2px 0 rgba(56, 99, 98);
}

.greenBox {
  display:inline-block;
  background: #7DEBA3;
  border-radius: 4px;
  border-width: thin;
  padding: 6px;
  padding-left: 15px; 
  margin-right: 5px;
  box-shadow: 0px 1px 2px 0 rgba(56, 99, 98);

}

.greyBox {
  display:inline-block;
  background: #d3d3d3;
  border-radius: 4px;
  padding: 6px;
  padding-left: 15px; 
  margin-right: 5px;
  box-shadow: 0px 1px 2px 0 rgba(56, 99, 98);

}

.inline {
  display:inline;
  margin-left: 5px;
  font-weight: 500;
  font-size: 14px;
}


.pending {
  /* background-color: #f6c575; */
}


.container {
  display: block;
  position: relative;
  margin-bottom: 18px; 
  margin-left: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
  height: 18px;
  width: 18px;
  background-color: #eee;
}

On mouse-over, add a grey background color
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sticky{
  position: sticky;
  top: 0px;
  z-index: 1;
}

.table.sticky th.custom-color{
  padding-top: 20px;
}

::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}


.custom-header {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #fff;
  background-color: #6987af;
}

.scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
}

.bottomView{
  cursor:pointer;
  position: absolute;
}


.dropdown-menu show{
  background-color: red;
  position: absolute;
  top: 0px;
  left: -66px;
  transform: translate3d(0px, 18px, 0px);
  will-change: transform;
}

.float{
	position:fixed;
	width:35px;
	height:35px;
	bottom:38px;
	right: 135px;
	background-color:#6987af;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  box-shadow: 2px 2px 3px #999;
  cursor: pointer;
}

.my-float{
	margin-top:22px;
}

.clickable-text{
  font-weight: bold;
  color: #6E87AC;
  cursor: pointer;
}
.multiline {
  white-space: pre-wrap;
  word-break: break-all;
  max-width: 200px;
  line-height: 1.5
}