
.custom-store{
  font-weight: bolder;
}

.action-css {
  display: flex;
  justify-content: space-between;
}

.no-data {
  display: flex;
  justify-content: center;
  height: 70vh;
}

.table-custom-header td {
  padding: 12px 12px 12px 12px;
}

.table-custom-header th {
  background-color: #6987AF;
  padding: 12px 12px 12px 12px;
}

.table-custom-header {
  border-collapse: separate;
  border-spacing: 0px 10px;
}

.custom-thead tr:first-of-type th {
  box-shadow:0 18px #6987AF
}

.custom-thead .custom-color{
  background-color: #8CAFD2;
}

.custom-thead .second-head{
  background-color: #8CAFD2;
}

.custom-display {
  display: flex;
  flex-direction: column;
}

.cursor {
  cursor: pointer;
}

.col-sm-6 {
  background: #999;
}

.background-color {
  background: #f5f7fc;
}

.other {
  background: #88B040;
}

.other2 {
  background: #777;
}

.p {
  text-align: center;
  padding-top: 120px;
}

.p a {
  text-decoration: underline;
  color: blue;
}

.scan-bottom-div {
  height: 100vh;
}

.custom-padding{
  padding: 0px 25px;
}