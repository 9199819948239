  .custom-store{
    font-weight: bolder;
  }

  .w-100{
    width: 80%!important;
  }

  .w-10{
    width: 10%!important;
  }

  .w-30{
    width: 30%!important;
  }

  .custom-display {
    display: flex;
    flex-direction: column;
  }

  .form-check-label {
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    font-weight: 500;
}
  
  
  