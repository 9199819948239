.col-form-label {
  /* font-weight: bold; */
  color: grey;
}

.form-control {
  color: black;
  /* font-weight: bold; */
}

.modal-header {
  border: none;
}

.modal-body {
  padding-left: 60px;
  /* padding-right: 20px; */
}

.header {
  font-weight: 600;
  color: black;
}

.optional-text{
  font-size: 12px;
}

.modal-content {
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
}

.my-modal {
  width: 43vw;
  max-width: 48vw;
}

.save-store {
  box-shadow: 0px 5px 11px 0 rgba(56, 99, 98, 0.59);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
