.textSearch {
  text-decoration: none;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  background-color: rgb(255, 255, 255);
  border: none;
  color: #0d0d0d;
  align-self: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-left: 5px;
  width: 85%;
  border: 2px solid rgb(255, 255, 255);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="text"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #ffffff;
}

input[type="text"]:placeholder {
  color: #cccccc;
}

.login {
  font-family: "Montserrat", sans-serif;
  background-color: #ffff;
  height: fit-content;
  display: flex;
}
#login .btn-custom ,.btn-custom:hover,
.btn-custom:active,
.btn-custom:visited,
.btn-custom:focus,
.btn-outline-custom,
.btn-outline-custom:focus {
  background-color: #ffff;
}


.custom-logout {
  color: #4f8180;
  line-height: 18px;
  font-weight: bold;
}

.custom-btn{
  border: 2px solid #4f8180;
}

.shadow-lg {
  height: 6.99vh;
}

.search-wrapper {
  display: inline;
}
