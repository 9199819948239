.sidenav {
  height: 100%;
  text-align: left;
  width: 250px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    to right,
    rgba(27, 35, 66),
    rgba(56, 76, 118)
  );
  align-items: center;
}
.eclipse {
  width: 200px;
  height: 100px;
  transform: rotate(20deg);
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
  border: 10px solid rgba(62, 73, 103);
  border-bottom: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus,
.btn-outline-primary,
.btn-outline-primary:focus {
  background-color: #4f8180;
  border-color: #4f8180;
}

.list-container {
  padding-top: 60px;
}

.list-heading {
  padding-left: 10px;
}

p {
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
td {
  font-family: "Montserrat", sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.65;
  letter-spacing: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: #000000;
}
.Rounded-Rectangle-14-delete {
  text-align: center;
  display: inline-block;
}

.custom-border {
  border: 6px solid rgba(244, 247, 252);
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-top: 40px;
}

td {
  border: solid 1px #000;
  border-style: solid none;
  padding: 10px;
  background-color: #fff;
}



.table > tbody > tr > td {
  vertical-align: middle;
  padding-left: 10px;
}
td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

th:first-child {
  border-left-style: solid;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
th:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.Forma-1-delete {
  width: 18px;
  height: 30px;
  object-fit: contain;
}
.Forma-1 {
  width: 16px;
  height: 30px;
  object-fit: contain;
}
.Rounded-Rectangle-14 {
  border-radius: 2px;
  text-align: center;
  display: inline-block;
}
th {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #fff;
  background-color: #6987af;
}

.border-image {
  border: 10px solid #404f7a;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.bottom-div {
  padding: 0px 25px;
  height: 100vh;
}

.username {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.04;
  letter-spacing: normal;
  color: #95aece;
  text-transform: capitalize;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 20px;
}

.list {
  margin: 6px 0px 50px 0px;
  padding: 20px 20px 20px 10px;
  text-decoration: none;
  color: rgba(165, 192, 222);
  display: block;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  cursor: pointer;
}

.list-active {
  margin: 6px 0px 50px 0px;
  padding: 20px 20px 20px 7px;
  border-left: 3px solid #e79723 !important;
  text-decoration: none;
  color: #e79723;
  background-color: rgba(3, 10, 27, 0.4);
  display: block;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
}

.custom-class {
  line-height: 30px;
  font-weight: bold;
}

.imageContainer {
  text-align: center;
  padding-top: 50px;
}

.sidenav a:hover {
  /* color: #f1f1f1; */
}

.header-container {
  background-color: #222845;
  text-align: center;
}
.top-buffer {
  margin-top: 20px;
}

.main {
  margin-left: 250px;
  background-color: #fff;
  background-color: rgba(244, 247, 252);
  height: "100%";
  overflow:auto;
  justify-content: space-between;
}

.topHeader {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  padding: 10px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.add-store {
  height: fit-content;
  box-shadow: 0px 2px 10px 0 rgba(56, 99, 98, 0.59);
  background-color: #4f8180;
}


::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
