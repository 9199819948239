.custom-store {
  font-weight: bolder;
}

.action-css {
  display: flex;
  justify-content: space-between;
}

.cursor {
  cursor: pointer;
}

.font-13 {
  font-size: 13px;
}

.custom-header {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #fff;
  background-color: #6987af;
}

.scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
}

.removeButton{
  cursor:pointer;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #4f8180;
  color: #fff;
  text-align: center;
  padding: 5px;
}
